body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.faq-page {
  text-align: center; }
  .faq-page h1 {
    margin: 40px 0;
    color: #008aae; }
  .faq-page__section {
    padding: 120px 0; }
  .faq-page .faq-point {
    border-bottom: 1px solid #E5E5E5;
    transition: all 0.6s ease-in-out;
    padding: 20px 15px;
    text-align: left; }
    .faq-page .faq-point__top {
      display: flex;
      font-weight: 500;
      font-size: 18px;
      user-select: none;
      cursor: pointer;
      padding: 10px 0; }
      .faq-page .faq-point__top div:first-child {
        flex-grow: 1; }
    .faq-page .faq-point__arrow {
      width: 20px;
      height: 12px; }
      .faq-page .faq-point__arrow svg {
        transition: all 0.6s ease-in-out;
        width: 20px;
        height: 12px; }
    .faq-page .faq-point__answer {
      max-height: 0;
      overflow: hidden;
      font-size: 16px;
      margin: 10px 0;
      transition: all 0.6s ease-in-out; }
    .faq-page .faq-point.faq-point--open {
      background: #E5E5E5; }
      .faq-page .faq-point.faq-point--open .faq-point__answer {
        max-height: 300px; }
      .faq-page .faq-point.faq-point--open .faq-point__arrow svg {
        transform: rotate(180deg); }
