@keyframes fadeOut {
  100% {
    opacity: 1; }
  0% {
    opacity: 0; } }

.dashbord_control-icon {
  fill: #fff; }

.mouse-move-container {
  pointer-events: none;
  top: 70%;
  width: 100%;
  color: #fff;
  position: fixed;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: fadeOut 5s 3s ease-in-out;
  opacity: 0; }

@media (max-width: 450px) {
  .mouse-move-container {
    top: 65%; } }

.mouse-move-png {
  width: 106px;
  height: 66px; }

.embed {
  overflow: hidden;
  color: #e8e8e8; }
  .embed .avatar {
    position: fixed;
    top: 16px;
    left: 16px;
    max-width: 100px;
    min-width: 50px;
    max-height: 100px;
    min-height: 50px;
    width: 7vw;
    height: 7vw;
    background-size: contain;
    background-repeat: no-repeat; }
  .embed .play_button {
    position: fixed;
    z-index: 200;
    width: 100px;
    height: auto;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: all;
    cursor: pointer; }
  .embed .evr .ui-overlay {
    opacity: 0; }
  .embed .overlay {
    width: 100vw;
    position: fixed;
    z-index: 40;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.5) 60px);
    bottom: 0;
    padding-bottom: 10px;
    top: auto; }
    .embed .overlay .overlay__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100vw;
      left: 0;
      bottom: 0;
      top: auto; }
      .embed .overlay .overlay__header .content {
        flex: 1;
        flex-basis: 0.000000001px;
        margin-top: 0;
        margin-left: 16px;
        height: 19px; }
        .embed .overlay .overlay__header .content .presentation-name-container {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 300px; }
        .embed .overlay .overlay__header .content .title {
          white-space: nowrap;
          overflow: hidden;
          user-select: none;
          pointer-events: none;
          font-size: 1em;
          padding-left: 30px;
          font-weight: 300; }
          .embed .overlay .overlay__header .content .title .presentation-name {
            flex: 1;
            color: white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 27px;
            display: inline-block;
            line-height: 27px;
            font-weight: 300;
            vertical-align: top; }
      .embed .overlay .overlay__header .controls {
        margin-right: 16px;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap-reverse; }
        .embed .overlay .overlay__header .controls .flex-container {
          flex: 0 1 auto;
          padding: 3px;
          margin-right: 20px; }
        .embed .overlay .overlay__header .controls .slider {
          margin-top: 6px;
          margin-left: 10px;
          margin-right: 10px;
          height: 2px;
          width: 100px; }
          .embed .overlay .overlay__header .controls .slider .rc-slider-rail {
            background-color: white;
            height: 2px; }
          .embed .overlay .overlay__header .controls .slider .rc-slider-track {
            background-color: white;
            height: 2px; }
          .embed .overlay .overlay__header .controls .slider .rc-slider-handle {
            border: none;
            border-radius: 0;
            background-color: whitesmoke;
            width: 5px;
            height: 20px;
            margin-top: -9px;
            transform: none; }
        .embed .overlay .overlay__header .controls .slider-container {
          display: flex;
          flex-direction: row; }
        .embed .overlay .overlay__header .controls .slider-icons {
          color: white;
          margin: 0;
          cursor: pointer; }
        .embed .overlay .overlay__header .controls .imageIcon {
          margin-left: 8px;
          width: 30px;
          height: 30px;
          cursor: pointer;
          color: white;
          fill: white; }
        .embed .overlay .overlay__header .controls .control {
          width: 20px;
          height: 20px;
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
          display: inline-block;
          margin: 0 10px;
          cursor: pointer; }
          .embed .overlay .overlay__header .controls .control.vr_btn {
            width: 26px;
            background-image: url("/static/home/presentation/icon7.png"); }
          .embed .overlay .overlay__header .controls .control.fs_btn {
            background-image: url("/static/home/presentation/icon6.png"); }
          .embed .overlay .overlay__header .controls .control.ms_btn {
            background-image: url("/static/home/presentation/icon8.png"); }
          .embed .overlay .overlay__header .controls .control.scrollButton {
            transform: scaleY(1);
            transition: .3s transform; }
            .embed .overlay .overlay__header .controls .control.scrollButton svg {
              fill: #fff; }
        .embed .overlay .overlay__header .controls .controls-centered {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%); }
          .embed .overlay .overlay__header .controls .controls-centered svg {
            fill: #fff; }
          .embed .overlay .overlay__header .controls .controls-centered .arrow-left svg {
            transform: rotateZ(-90deg) scale(1.3); }
          .embed .overlay .overlay__header .controls .controls-centered .arrow-right svg {
            transform: rotateZ(90deg) scale(1.3); }
          .embed .overlay .overlay__header .controls .controls-centered span.control {
            margin: 0 20px; }
          @media only screen and (max-width: 360px) {
            .embed .overlay .overlay__header .controls .controls-centered span.control {
              margin: 0 15px; } }
      @media (max-width: 920px) {
        .embed .overlay .overlay__header .controls {
          margin: 0 auto; }
          .embed .overlay .overlay__header .controls .controls-centered {
            position: static;
            transform: none;
            margin-bottom: 10px; } }
    .embed .overlay .header1 {
      margin-bottom: 0; }
    @media (max-width: 685px) {
      .embed .overlay .header1 {
        margin-bottom: -60px; } }
    .embed .overlay .owl-nav {
      pointer-events: none; }
      .embed .overlay .owl-nav .owl-prev, .embed .overlay .owl-nav .owl-next {
        position: absolute;
        bottom: -25px;
        text-indent: -2000px;
        height: 80px;
        width: 110px;
        background: url("/static/home/embed/arrow.png") no-repeat 80px center, linear-gradient(to right, transparent 0, rgba(0, 0, 0, 0.3) 110px);
        pointer-events: all; }
        .embed .overlay .owl-nav .owl-prev.disabled, .embed .overlay .owl-nav .owl-next.disabled {
          display: none; }
      .embed .overlay .owl-nav .owl-prev {
        left: 0;
        transform: scaleX(-1); }
      .embed .overlay .owl-nav .owl-next {
        right: 0; }
    .embed .overlay .scenes-container {
      margin: 0;
      width: auto; }
      .embed .overlay .scenes-container .scene {
        position: relative;
        display: inline-block;
        width: 170px;
        box-sizing: border-box;
        pointer-events: all;
        cursor: pointer;
        padding: 6px 6px 0 6px; }
        .embed .overlay .scenes-container .scene .scene-background {
          display: block;
          height: 80px;
          opacity: .7;
          transition: all 0.2s;
          border-radius: 4px;
          overflow: hidden; }
        .embed .overlay .scenes-container .scene .scene-name {
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          transition: all 0.2s;
          color: #fff;
          opacity: .7;
          padding: 7px 14px 17px 14px; }
          .embed .overlay .scenes-container .scene .scene-name span {
            display: block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
    .embed .overlay::before {
      content: none; }
    .embed .overlay.overlay--opened .owl-nav .owl-prev, .embed .overlay.overlay--opened .owl-nav .owl-next {
      bottom: 35px; }
    .embed .overlay.overlay--opened .overlay__header .controls .control.scrollButton.inverse {
      transform: scaleY(-1); }
    .embed .overlay.overlay--opened .scene.isSelected .scene-background, .embed .overlay.overlay--opened .scene:hover .scene-background {
      opacity: 1;
      box-shadow: 0 0 14px rgba(255, 255, 255, 0.4); }
    .embed .overlay.overlay--opened .scene.isSelected .scene-name, .embed .overlay.overlay--opened .scene:hover .scene-name {
      opacity: 1; }
  .embed .autoplay.control {
    height: 24px;
    width: 24px; }
    .embed .autoplay.control svg {
      fill: #fff; }
  @media only screen and (max-width: 48em) {
    .embed .overlay .overlay__header .controls .control.scrollButton {
      top: -24px; }
    .embed .play_button {
      width: 75px; } }
  @media (max-width: 685px) {
    .embed .overlay .header2 {
      flex-direction: column;
      height: 90px; } }
  @media (max-width: 500px) {
    .embed .overlay .header2 {
      flex-direction: column;
      height: 115px; }
      .embed .overlay .header2 .controls {
        flex-direction: column; } }
  @media (max-width: 920px) {
    .embed .overlay--opened {
      top: auto;
      height: 213px;
      transition: .5s height ease-in-out; } }
  .embed .owner-info {
    display: flex;
    margin: 20px 20px 0;
    height: 80px;
    overflow: hidden; }
    .embed .owner-info .owner-avatar {
      border-radius: 25px; }
    .embed .owner-info .description-box {
      display: flex;
      flex-direction: column;
      margin-left: 20px; }
      .embed .owner-info .description-box .description-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  .embed .details-box {
    display: flex;
    justify-content: flex-end;
    padding-right: 22px;
    font-size: 12px;
    color: white;
    flex-shrink: 0; }
    .embed .details-box > div {
      display: flex;
      margin-left: 18px; }
    .embed .details-box svg {
      margin-top: 1px;
      margin-right: 8px;
      display: block; }
  @media (max-width: 450px) {
    .embed .overlay .header2 .controls {
      margin: 0; }
      .embed .overlay .header2 .controls .flex-container {
        margin: 0; }
    .embed .overlay--opened {
      top: auto; } }

.display-flex {
  display: flex; }
