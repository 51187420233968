body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.carousel__arrows {
  padding: 0 8%;
  top: 68%; }
  .carousel__arrows--explore svg {
    display: block;
    width: 5px;
    height: 7px; }

.carousel__slide--explore {
  height: 220px;
  background-position: center;
  background-size: cover; }

.carousel__container--explore {
  text-align: center;
  padding-top: 110px; }

.carousel__title--explore {
  margin-bottom: 10px; }

.carousel__button--explore {
  border: 1px solid #FFF100;
  border-radius: 5px;
  padding: 5px 15px; }
