body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.searchBar {
  margin: 40px 0 15px; }
  .searchBar .buttonsContainer {
    margin: 0 auto;
    display: flex;
    justify-content: center; }
    .searchBar .buttonsContainer .button {
      letter-spacing: 0.5px;
      flex-grow: 1;
      border-radius: 0px;
      margin: 0; }
      @media (max-width: 1280px) {
        .searchBar .buttonsContainer .button {
          font-size: 8px; } }
      .searchBar .buttonsContainer .button:last-child {
        border-right: 0px; }
    .searchBar .buttonsContainer .inactive {
      color: #333333;
      background-color: #EFEFEF;
      border-right: 1px solid #999; }
    .searchBar .buttonsContainer .active {
      color: #ffffff;
      background: #008aae;
      border-right: 0px; }
  .searchBar .inputContainer {
    text-align: center;
    width: 175px;
    margin: 0 auto 30px;
    transform: translateY(0); }
    .searchBar .inputContainer input {
      margin-bottom: 0;
      height: 16.8px;
      outline: none;
      border-color: rgba(0, 0, 0, 0);
      box-sizing: border-box;
      color: #666666;
      -webkit-text-fill-color: #666666;
      font-size: 13px;
      border-radius: 7px;
      padding: 5px 8px 5px 25px;
      background-image: url(/static/home/icons/pic-magnifier.svg);
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 12px 12px;
      background-color: #EFEFEF;
      font-family: "brandon", sans-serif;
      font-weight: 400; }
  .searchBar .disabled {
    color: #808080 !important; }
  @media (min-width: 768px) {
    .searchBar {
      margin: 40px 15px 15px; }
      .searchBar .buttonsContainer {
        max-width: 650px; }
        .searchBar .buttonsContainer .button {
          border-radius: 7px;
          font-size: 18px;
          letter-spacing: 2px; }
          .searchBar .buttonsContainer .button:nth-child(1) {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px; }
          .searchBar .buttonsContainer .button:nth-child(2), .searchBar .buttonsContainer .button:nth-child(3) {
            border-radius: 0; }
          .searchBar .buttonsContainer .button:last-child {
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px; }
      .searchBar .inputContainer input {
        height: 24.8px; } }
  @media (min-width: 1280px) {
    .searchBar {
      flex-wrap: nowrap;
      position: relative; }
      .searchBar .buttonsContainer {
        max-width: 500px; }
        .searchBar .buttonsContainer .button {
          font-size: 13px;
          letter-spacing: 0.5px; }
      .searchBar .inputContainer {
        position: absolute;
        left: 0;
        text-align: left;
        margin: 0; }
        .searchBar .inputContainer input {
          height: 28.4px; } }
