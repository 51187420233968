body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.explore {
  position: relative;
  min-height: 500px; }
  .explore > .row {
    max-width: 1059px;
    margin: 0 auto; }
  .explore .noData {
    position: relative;
    min-height: 300px; }
    .explore .noData h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center; }
  .explore .loading {
    position: relative;
    min-height: 300px; }
    .explore .loading svg {
      width: 300px;
      height: 300px; }
  .explore .pagination {
    text-align: center;
    max-width: 400px;
    margin: 0 auto; }
    .explore .pagination .previous, .explore .pagination .next {
      display: none; }
    .explore .pagination li {
      display: inline-block;
      margin: 17px;
      width: 10px; }
      .explore .pagination li a {
        color: #676767;
        font-weight: 300;
        transition: .2s all;
        transform: translateY(5px); }
        .explore .pagination li a:focus {
          outline: none; }
    .explore .pagination .selected {
      color: #008aae;
      transform: scale(1.5);
      font-weight: 600; }
