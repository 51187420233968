body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.home .hero {
  display: flex;
  align-items: flex-end;
  width: 100vw;
  height: 102vw;
  max-height: 400px;
  overflow: hidden;
  position: relative;
  color: #ffffff;
  background-image: url(/static/home/home/home-video.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat; }
  @media (min-width: 768px) {
    .home .hero {
      height: 71vh;
      max-height: none;
      min-height: 530px; } }
  @media (min-width: 1280px) {
    .home .hero {
      height: 69.6vh; } }
  .home .hero__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0; }
    @media (max-width: 750px) {
      .home .hero__video {
        display: none; } }
  .home .hero__mobile {
    display: none; }
    @media (max-width: 750px) {
      .home .hero__mobile {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0; } }
  .home .hero__wrapper {
    position: relative;
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding: 0 25px;
    z-index: 2; }
    @media (min-width: 768px) {
      .home .hero__wrapper {
        padding: 0 68px; } }
    @media (min-width: 1280px) {
      .home .hero__wrapper {
        padding: 0 60px;
        max-width: 1060px; } }
  .home .hero__hgroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px; }
    @media (min-width: 768px) {
      .home .hero__hgroup {
        align-items: flex-start;
        margin-bottom: 98px; } }
    @media (min-width: 1280px) {
      .home .hero__hgroup {
        margin-bottom: 150px; } }
  .home .hero__heading {
    margin-bottom: 8px;
    max-width: 200px; }
    @media (min-width: 768px) {
      .home .hero__heading {
        margin-bottom: 10px;
        max-width: 350px; } }
  .home .hero__subheading {
    margin-bottom: 8px;
    max-width: 185px; }
    @media (min-width: 768px) {
      .home .hero__subheading {
        margin-bottom: 15px;
        max-width: 330px; } }
    @media (min-width: 1280px) {
      .home .hero__subheading {
        margin-bottom: 25px;
        max-width: 260px; } }
  .home .hero__button {
    margin-bottom: 8px; }
    @media (min-width: 768px) {
      .home .hero__button {
        margin-bottom: 8px; } }
  .home .hero__tagline {
    font-size: 11px;
    max-width: 168px;
    letter-spacing: 1.3px;
    color: white; }
    @media (min-width: 768px) {
      .home .hero__tagline {
        font-size: 20px;
        max-width: 300px;
        line-height: 1.1em; } }
    @media (min-width: 1280px) {
      .home .hero__tagline {
        max-width: 180px;
        font-size: 12px;
        line-height: 1.5em;
        letter-spacing: 1.3px; } }

.home .tabs {
  margin: 0 auto;
  width: 640px; }

.home .tabsFull {
  display: none; }
  @media (min-width: 1280px) {
    .home .tabsFull {
      display: block; } }

.home .tabsMob {
  display: block; }
  @media (min-width: 1280px) {
    .home .tabsMob {
      display: none; } }

.home .product {
  text-align: center;
  padding: 22px 0 15px 0; }
  @media (min-width: 768px) {
    .home .product {
      padding: 64px 0 72px 0; } }
  @media (min-width: 1280px) {
    .home .product {
      padding: 35px 0 8px 0; } }
  .home .product__heading {
    padding: 0 20px;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .home .product__heading {
        padding: 0 50px;
        margin-bottom: 36px; } }
    @media (min-width: 1280px) {
      .home .product__heading {
        max-width: 540px;
        margin: 0 auto;
        margin-bottom: 28px; } }
  .home .product .tabs-container__content-container {
    display: flex;
    flex-direction: column; }
    @media (min-width: 1280px) {
      .home .product .tabs-container__content-container {
        flex-direction: row-reverse; } }
    .home .product .tabs-container__content-container > div > div {
      text-align: center;
      margin-bottom: 0px; }
  .home .product .tabs-container__img {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin: 12px auto;
    display: block;
    height: 250px;
    width: 90vw; }
    .home .product .tabs-container__img.tabs-container__img-product-1 {
      background-image: url("/static/home/home/product-1.jpg"); }
    .home .product .tabs-container__img.tabs-container__img-product-2 {
      background-image: url("/static/home/home/product-2.jpg"); }
    .home .product .tabs-container__img.tabs-container__img-product-3 {
      background-image: url("/static/home/home/product-3.jpg"); }
    .home .product .tabs-container__img.tabs-container__img-product-4 {
      background-image: url("/static/home/home/product-4.jpg"); }
  .home .product .tabs-container__button {
    color: #333333 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border-right: 2px solid #E5E5E5 !important; }
    .home .product .tabs-container__button:first-child {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important; }
    .home .product .tabs-container__button:last-child {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      border-right: none !important; }
    .home .product .tabs-container__button:not([style*="color: rgba(255, 255, 255, 0.7)"]) {
      color: #ffffff !important;
      background-color: #008aae !important;
      border-right: none !important; }
    .home .product .tabs-container__button > div > span {
      display: none !important; }
    .home .product .tabs-container__button > div > div {
      height: 34px !important; }
  .home .product .tabs-container__content {
    padding: 0 15px;
    font-weight: 500;
    margin-bottom: 18px; }
    @media (min-width: 768px) {
      .home .product .tabs-container__content {
        margin-bottom: 30px; } }
    @media (min-width: 1280px) {
      .home .product .tabs-container__content {
        text-align: left;
        max-width: 425px;
        padding: 0 20px;
        margin-top: 109px;
        margin-left: 130px; } }
  .home .product .tabs-container__tab-heading {
    margin-bottom: 12px; }
    @media (min-width: 768px) {
      .home .product .tabs-container__tab-heading {
        margin-bottom: 16px; } }
    @media (min-width: 1280px) {
      .home .product .tabs-container__tab-heading {
        margin-bottom: 10px; } }
  .home .product .tabs-container__description {
    letter-spacing: -.3px;
    line-height: 1.35em; }
    @media (min-width: 768px) {
      .home .product .tabs-container__description {
        line-height: 1.8em;
        font-size: 18px;
        padding: 0 70px; } }
    @media (min-width: 1280px) {
      .home .product .tabs-container__description {
        font-size: 13px;
        line-height: 1.5em;
        padding: 0; } }
  @media (min-width: 768px) {
    .home .product .tabs-container__content-container > div > div {
      padding: 0 6px;
      margin-bottom: 87px; }
    .home .product .tabs-container__img {
      margin: 42px auto;
      height: 338px; } }
  @media (min-width: 1280px) {
    .home .product .tabs-container__tabs > div:nth-child(2) {
      display: block !important; }
    .home .product .tabs-container__content-container {
      max-width: 1220px;
      padding: 0 25px;
      margin: 0 auto; }
      .home .product .tabs-container__content-container > div > div {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 15px; }
    .home .product .tabs-container__img {
      width: 605px;
      height: 338px; } }

.home .divider {
  background-image: url(/static/home/home/graph-divider-1.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 53vw;
  max-width: 725px;
  margin: 0 auto 74px auto; }
  @media (min-width: 768px) {
    .home .divider {
      margin-bottom: 66px;
      height: 50vw;
      max-height: 385px; } }
  @media (min-width: 1280px) {
    .home .divider {
      background-image: url(/static/home/home/graph-divider-1-big.jpg);
      height: 33vw;
      max-width: 1256px;
      max-height: none;
      margin-bottom: -11px; } }

.home .benefits {
  text-align: center; }
  @media (min-width: 768px) {
    .home .benefits {
      padding-top: 84px; } }
  @media (min-width: 1280px) {
    .home .benefits {
      max-width: 820px; } }
  .home .benefits__heading {
    margin-bottom: 8px; }
    @media (min-width: 768px) {
      .home .benefits__heading {
        margin-bottom: 16px; } }
    @media (min-width: 1280px) {
      .home .benefits__heading {
        margin-bottom: 12px; } }
  .home .benefits__subheading {
    font-size: 10px;
    color: #333333;
    max-width: 150px;
    margin: 0 auto 24px auto; }
    @media (min-width: 768px) {
      .home .benefits__subheading {
        font-size: 20px;
        max-width: 270px;
        margin-bottom: 52px; } }
    @media (min-width: 1280px) {
      .home .benefits__subheading {
        font-size: 14px;
        line-height: 1.5em;
        margin-bottom: 128px; } }
  .home .benefits__box-container {
    margin-bottom: 80px; }
    @media (min-width: 768px) {
      .home .benefits__box-container {
        margin-bottom: 156px; } }
  .home .benefits__box {
    margin-bottom: 27px; }
    @media (min-width: 768px) {
      .home .benefits__box {
        margin-bottom: 93px; } }
    @media (min-width: 1280px) {
      .home .benefits__box {
        display: flex;
        margin-bottom: 75px; } }
    @media (min-width: 1280px) {
      .home .benefits__box--second {
        flex-direction: row-reverse; }
        .home .benefits__box--second .benefits__box-content {
          margin-top: 112px; } }
  .home .benefits__img {
    max-width: 160px;
    height: auto !important;
    margin-bottom: -10px; }
    @media (min-width: 768px) {
      .home .benefits__img {
        max-width: 100%;
        margin-bottom: -15px; } }
    @media (min-width: 1280px) {
      .home .benefits__img {
        height: 331px; } }
  @media (min-width: 1280px) {
    .home .benefits__box-content {
      margin-top: 95px;
      padding: 0 20px; } }
  .home .benefits__box-heading {
    font-size: 13px;
    margin-bottom: 13px;
    font-weight: 700;
    position: relative;
    color: #333333;
    line-height: 1.3em; }
    @media (min-width: 768px) {
      .home .benefits__box-heading {
        font-size: 26px;
        margin-bottom: 28px; } }
    @media (min-width: 1280px) {
      .home .benefits__box-heading {
        font-size: 22px;
        text-align: left;
        font-weight: 500; } }
  .home .benefits__box-description {
    line-height: 1.35em; }
    @media (min-width: 768px) {
      .home .benefits__box-description {
        font-size: 18px;
        line-height: 1.8em;
        padding: 0 70px;
        min-height: 130px; } }
    @media (min-width: 1280px) {
      .home .benefits__box-description {
        font-size: 13px;
        padding: 0;
        text-align: left;
        line-height: 1.4em; } }

.home .how-to-start {
  text-align: center;
  padding-left: 0;
  padding-right: 0; }
  .home .how-to-start a {
    color: #008aae; }
  @media (min-width: 1280px) {
    .home .how-to-start {
      max-width: 1280px; } }
  .home .how-to-start__heading {
    margin-bottom: 8px; }
    @media (min-width: 768px) {
      .home .how-to-start__heading {
        margin-bottom: 16px; } }
    @media (min-width: 1280px) {
      .home .how-to-start__heading {
        margin-bottom: 12px; } }
  .home .how-to-start__subheading {
    color: #333333;
    max-width: 100%;
    margin: 0 auto 24px auto; }
    @media (min-width: 768px) {
      .home .how-to-start__subheading {
        max-width: 500px; } }
    @media (min-width: 1280px) {
      .home .how-to-start__subheading {
        font-size: 14px;
        line-height: 1.5em; } }
  .home .how-to-start__box-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center; }
    @media (min-width: 1280px) {
      .home .how-to-start__box-container {
        align-items: stretch;
        flex-direction: row; } }
  .home .how-to-start__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px;
    margin-bottom: 32px;
    background-color: #f1f1f1;
    padding: 16px 32px 48px 32px;
    width: 403px; }
    @media (min-width: 1280px) {
      .home .how-to-start__box {
        width: 100%; } }
    @media (min-width: 1280px) {
      .home .how-to-start__box--second {
        flex-direction: row-reverse; }
        .home .how-to-start__box--second .benefits__box-content {
          margin-top: 112px; } }
  @media (max-width: 420px) {
    .home .how-to-start__box {
      width: auto; } }
  .home .how-to-start__image {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    margin-bottom: 8px; }
  .home .how-to-start__box-heading {
    font-size: 13px;
    margin-bottom: 13px;
    font-weight: 700;
    position: relative;
    color: #333333;
    line-height: 1.3em;
    text-align: center !important; }
    @media (min-width: 768px) {
      .home .how-to-start__box-heading {
        font-size: 26px;
        margin-bottom: 28px; } }
    @media (min-width: 1280px) {
      .home .how-to-start__box-heading {
        font-size: 22px;
        text-align: left;
        font-weight: 500; } }
  .home .how-to-start__box-description {
    line-height: 1.35em;
    text-align: left; }

.home .cta {
  text-align: center;
  background-image: url("/static/home/home/img-cta-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 17px;
  padding-bottom: 10px; }
  @media (min-width: 768px) {
    .home .cta {
      padding-top: 40px;
      padding-bottom: 45px; } }
  @media (min-width: 1280px) {
    .home .cta {
      padding-top: 57px;
      padding-bottom: 60px; } }
  .home .cta__heading {
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .home .cta__heading {
        font-size: 26px;
        margin-bottom: 22px; } }
    @media (min-width: 1280px) {
      .home .cta__heading {
        font-size: 23px; } }
  @media (max-width: 767px) {
    .home .cta__button {
      padding: 2px 28px; } }

.home .features {
  padding-top: 50px;
  padding-bottom: 23px;
  background-color: #F6F6F6; }
  @media (min-width: 768px) {
    .home .features {
      padding-top: 109px;
      padding-bottom: 38px; } }
  @media (min-width: 1280px) {
    .home .features {
      padding-top: 70px;
      padding-bottom: 26px;
      padding-left: 0;
      padding-right: 0; } }
  .home .features__heading {
    text-align: center;
    margin-bottom: 25px; }
    @media (min-width: 768px) {
      .home .features__heading {
        margin-bottom: 60px; } }
  .home .features__container {
    max-width: 1280px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .home .features__container {
        margin-bottom: 150px; }
        .home .features__container:last-child {
          margin-bottom: 0; } }
    @media (min-width: 1280px) {
      .home .features__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 68px; } }
    .home .features__container--reversed {
      flex-flow: row-reverse; }
      @media (min-width: 1280px) {
        .home .features__container--reversed > .features__list {
          max-width: 30%;
          margin-left: 182px; } }
  .home .features__img {
    margin-bottom: 20px;
    width: 99%;
    margin: 0 auto 40px auto; }
    @media (min-width: 768px) {
      .home .features__img {
        margin-bottom: 53px; } }
    @media (min-width: 1280px) {
      .home .features__img {
        width: 50%;
        margin-top: 8px;
        margin-left: 0;
        margin-right: 0; } }
  .home .features__list {
    padding-left: 32px; }
    @media (min-width: 768px) {
      .home .features__list {
        padding-left: 74px; } }
    @media (min-width: 1280px) {
      .home .features__list {
        margin-top: 14px;
        padding-left: 52px;
        width: 42%; } }
  .home .features__item {
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .home .features__item {
        margin-bottom: 47px;
        padding-right: 15px; } }
    @media (min-width: 1280px) {
      .home .features__item {
        margin-bottom: 30px;
        max-width: 320px; } }
  .home .features__item-heading {
    position: relative; }
    @media (min-width: 768px) {
      .home .features__item-heading {
        margin-bottom: 6px; } }
    @media (min-width: 1280px) {
      .home .features__item-heading {
        margin-bottom: 10px; } }
    .home .features__item-heading::before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: -30px;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .home .features__item-heading::before {
          left: -68px; } }
    .home .features__item-heading--first::before {
      background-image: url("/static/home/home/features/img-feature-1.svg");
      width: 17px;
      height: 11px; }
      @media (min-width: 768px) {
        .home .features__item-heading--first::before {
          width: 38px;
          height: 25px; } }
      @media (min-width: 1280px) {
        .home .features__item-heading--first::before {
          width: 35px;
          height: 23px; } }
    .home .features__item-heading--second::before {
      background-image: url("/static/home/home/features/img-feature-2.svg");
      width: 17px;
      height: 13px; }
      @media (min-width: 768px) {
        .home .features__item-heading--second::before {
          width: 38px;
          height: 29px; } }
      @media (min-width: 1280px) {
        .home .features__item-heading--second::before {
          width: 35px;
          height: 26px; } }
    .home .features__item-heading--third::before {
      background-image: url("/static/home/home/features/img-feature-3.svg");
      width: 17px;
      height: 14px; }
      @media (min-width: 768px) {
        .home .features__item-heading--third::before {
          width: 38px;
          height: 31px; } }
      @media (min-width: 1280px) {
        .home .features__item-heading--third::before {
          width: 35px;
          height: 29px; } }
    .home .features__item-heading--fourth::before {
      background-image: url("/static/home/home/features/img-feature-4.svg");
      width: 18px;
      height: 13px; }
      @media (min-width: 768px) {
        .home .features__item-heading--fourth::before {
          width: 42px;
          height: 30px; } }
      @media (min-width: 1280px) {
        .home .features__item-heading--fourth::before {
          width: 35px;
          height: 25px; } }
    .home .features__item-heading--fifth::before {
      background-image: url("/static/home/home/features/img-feature-5.svg");
      width: 18px;
      height: 14px; }
      @media (min-width: 768px) {
        .home .features__item-heading--fifth::before {
          width: 42px;
          height: 32px; } }
      @media (min-width: 1280px) {
        .home .features__item-heading--fifth::before {
          width: 35px;
          height: 26px; } }
    .home .features__item-heading--sixth::before {
      background-image: url("/static/home/home/features/img-feature-6.svg");
      width: 18px;
      height: 10px; }
      @media (min-width: 768px) {
        .home .features__item-heading--sixth::before {
          width: 42px;
          height: 22px; } }
      @media (min-width: 1280px) {
        .home .features__item-heading--sixth::before {
          width: 35px;
          height: 18px; } }

.home .how-can-help {
  padding-top: 43px;
  padding-bottom: 18px;
  max-width: 1280px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .home .how-can-help {
      padding-top: 80px;
      padding-bottom: 118px; } }
  @media (min-width: 1280px) {
    .home .how-can-help {
      padding: 56px 16px 0; } }
  .home .how-can-help__heading {
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    color: #008aae; }
    @media (min-width: 768px) {
      .home .how-can-help__heading {
        font-size: 30px;
        letter-spacing: 1px;
        margin-bottom: -15px; } }
    @media (min-width: 1280px) {
      .home .how-can-help__heading {
        font-size: 27px;
        margin-bottom: 48px; } }
  @media (min-width: 1280px) {
    .home .how-can-help__boxes {
      display: flex;
      justify-content: space-between; } }
  .home .how-can-help__box {
    margin-bottom: 72px; }
    .home .how-can-help__box:last-child {
      margin-bottom: 0; }
    @media (min-width: 768px) {
      .home .how-can-help__box {
        margin-bottom: 80px; } }
    @media (min-width: 1280px) {
      .home .how-can-help__box {
        width: 31%;
        margin-bottom: 20px; } }
    .home .how-can-help__box--second .how-can-help__box-title {
      color: #008aae; }
    .home .how-can-help__box--second .how-can-help__box-img {
      max-width: 261px; }
      @media (min-width: 768px) {
        .home .how-can-help__box--second .how-can-help__box-img {
          max-width: 100%; } }
      @media (min-width: 1280px) {
        .home .how-can-help__box--second .how-can-help__box-img {
          max-width: 374px; } }
    .home .how-can-help__box--third .how-can-help__box-title {
      color: #F4B934; }
    .home .how-can-help__box--third .how-can-help__box-img {
      max-width: 239px; }
      @media (min-width: 768px) {
        .home .how-can-help__box--third .how-can-help__box-img {
          max-width: 100%; } }
      @media (min-width: 1280px) {
        .home .how-can-help__box--third .how-can-help__box-img {
          max-width: 374px; } }
  .home .how-can-help__box-img {
    margin: 0 auto -10px auto;
    display: block;
    height: auto;
    max-width: 257px; }
    @media (min-width: 768px) {
      .home .how-can-help__box-img {
        max-width: 100%;
        margin: 0 auto -12px auto; } }
    @media (min-width: 1280px) {
      .home .how-can-help__box-img {
        max-width: 374px;
        margin: 0 auto; } }
  .home .how-can-help__box-title {
    font-size: 14px;
    font-weight: 500;
    color: #00908B;
    margin-left: 33px;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .home .how-can-help__box-title {
        font-size: 32px;
        margin-left: 73px;
        margin-bottom: 26px; } }
    @media (min-width: 1280px) {
      .home .how-can-help__box-title {
        margin-left: 57px;
        margin-bottom: 18px;
        font-size: 22px; } }
  .home .how-can-help__list {
    margin-left: 20px; }
    @media (min-width: 768px) {
      .home .how-can-help__list {
        margin-left: 74px;
        margin-right: 70px; } }
    @media (min-width: 1280px) {
      .home .how-can-help__list {
        margin-right: 40px; } }
  .home .how-can-help__list-item {
    font-size: 14px;
    line-height: 1.37em;
    position: relative;
    margin-bottom: 23px;
    font-weight: 500; }
    @media (min-width: 768px) {
      .home .how-can-help__list-item {
        font-size: 18px;
        line-height: 1.5em;
        margin-bottom: 35px; } }
    @media (min-width: 1280px) {
      .home .how-can-help__list-item {
        font-size: 13px;
        margin-bottom: 23px;
        line-height: 1.65em; } }
  .home .how-can-help__button-wrap {
    display: none;
    text-align: center;
    padding-bottom: 53px; }
    @media (min-width: 1280px) {
      .home .how-can-help__button-wrap {
        display: block; } }
  .home .how-can-help__30days {
    margin-bottom: 18px; }

.home .testimonials {
  background-color: #008aae;
  text-align: center;
  padding-top: 39px;
  padding-bottom: 48px;
  position: relative; }
  @media (min-width: 768px) {
    .home .testimonials {
      padding-top: 97px;
      padding-bottom: 84px;
      padding-left: 135px;
      padding-right: 135px; } }
  @media (min-width: 1280px) {
    .home .testimonials {
      padding-top: 53px;
      padding-bottom: 60px; } }
  .home .testimonials__head {
    color: #ffffff;
    font-weight: 700;
    letter-spacing: .5px;
    margin-bottom: 5px;
    position: relative; }
    @media (min-width: 768px) {
      .home .testimonials__head {
        font-size: 36px;
        margin-bottom: 1px;
        letter-spacing: .2px; } }
    @media (min-width: 1280px) {
      .home .testimonials__head {
        font-size: 22px;
        margin-bottom: 7px; } }
    .home .testimonials__head::before {
      content: url("/static/home/home/testimonials/quotes.svg");
      width: 11px;
      height: 9px;
      display: block;
      position: absolute;
      left: 50%;
      top: -15px;
      transform: translateX(-50%); }
      @media (min-width: 768px) {
        .home .testimonials__head::before {
          width: 25px;
          height: 21px;
          top: -35px; } }
      @media (min-width: 1280px) {
        .home .testimonials__head::before {
          width: 13px;
          height: 11px;
          top: -30px; } }
  .home .testimonials__subhead {
    color: #E5E5E5;
    letter-spacing: -.5px;
    margin-bottom: 8px; }
    @media (min-width: 768px) {
      .home .testimonials__subhead {
        font-size: 22px;
        margin-bottom: 18px; } }
    @media (min-width: 1280px) {
      .home .testimonials__subhead {
        font-size: 18px;
        margin-bottom: 32px; } }
  .home .testimonials .carousel {
    position: relative;
    margin: 0 auto;
    max-width: 360px; }
    @media (min-width: 768px) {
      .home .testimonials .carousel {
        max-width: 500px;
        max-height: 450px; } }
    @media (min-width: 1280px) {
      .home .testimonials .carousel {
        max-width: 700px;
        max-height: 500px; } }
    .home .testimonials .carousel__arrows {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      top: 37%;
      z-index: 2;
      padding: 0; }
      @media (min-width: 768px) {
        .home .testimonials .carousel__arrows {
          top: 49%; } }
      @media (min-width: 1280px) {
        .home .testimonials .carousel__arrows {
          top: 41%; } }
      .home .testimonials .carousel__arrows .prev svg, .home .testimonials .carousel__arrows .next svg {
        width: 7px;
        height: 12px;
        cursor: pointer; }
        @media (min-width: 768px) {
          .home .testimonials .carousel__arrows .prev svg, .home .testimonials .carousel__arrows .next svg {
            width: 14px;
            height: 22px; } }
        @media (min-width: 1280px) {
          .home .testimonials .carousel__arrows .prev svg, .home .testimonials .carousel__arrows .next svg {
            width: 11px;
            height: 17px; } }
      .home .testimonials .carousel__arrows .prev {
        position: absolute;
        left: -20px; }
      .home .testimonials .carousel__arrows .next {
        position: absolute;
        right: -20px; }
    .home .testimonials .carousel__slide .carousel__img {
      max-width: 100%;
      width: 57px;
      height: 57px;
      display: block;
      margin: 0 auto 7px auto; }
      @media (min-width: 768px) {
        .home .testimonials .carousel__slide .carousel__img {
          width: 130px;
          height: 130px;
          margin: 0 auto 26px auto; } }
      @media (min-width: 1280px) {
        .home .testimonials .carousel__slide .carousel__img {
          width: 82px;
          height: 82px;
          margin: 0 auto 14px auto; } }
    .home .testimonials .carousel__title {
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      position: relative;
      margin-bottom: 18px; }
      @media (min-width: 768px) {
        .home .testimonials .carousel__title {
          font-size: 28px;
          margin-bottom: 41px; } }
      @media (min-width: 1280px) {
        .home .testimonials .carousel__title {
          font-size: 16px;
          letter-spacing: .1px;
          margin-bottom: 26px; } }
      .home .testimonials .carousel__title::after {
        content: '';
        display: block;
        width: 16px;
        height: 1px;
        background-color: #FFF100;
        position: absolute;
        left: 50%;
        bottom: -7px;
        transform: translateX(-50%); }
        @media (min-width: 768px) {
          .home .testimonials .carousel__title::after {
            width: 42px;
            height: 2px;
            bottom: -19px; } }
        @media (min-width: 1280px) {
          .home .testimonials .carousel__title::after {
            width: 30px;
            bottom: -15px; } }
    .home .testimonials .carousel__description {
      color: #ffffff;
      line-height: 1.3em;
      letter-spacing: .3px; }
      @media (min-width: 768px) {
        .home .testimonials .carousel__description {
          font-size: 18px;
          letter-spacing: -.6px;
          line-height: 1.6em; } }
      @media (min-width: 1280px) {
        .home .testimonials .carousel__description {
          font-size: 13px;
          line-height: 1.4em; } }
  .home .testimonials__dot {
    position: absolute;
    display: block;
    border-radius: 50%; }
    .home .testimonials__dot--1 {
      width: 38px;
      height: 38px;
      background-color: #FFE382;
      top: 9%;
      left: -4%; }
      @media (min-width: 768px) {
        .home .testimonials__dot--1 {
          width: 87px;
          height: 87px;
          top: 12%;
          left: -3%; } }
      @media (min-width: 1280px) {
        .home .testimonials__dot--1 {
          width: 60px;
          height: 60px;
          top: 53%;
          left: -1%; } }
    .home .testimonials__dot--2, .home .testimonials__dot--3 {
      width: 12px;
      height: 12px;
      background-color: #FFE382; }
      @media (min-width: 768px) {
        .home .testimonials__dot--2, .home .testimonials__dot--3 {
          width: 28px;
          height: 28px; } }
    .home .testimonials__dot--2 {
      top: 22%;
      right: 16%; }
      @media (min-width: 768px) {
        .home .testimonials__dot--2 {
          top: 27%;
          right: 19%; } }
      @media (min-width: 1280px) {
        .home .testimonials__dot--2 {
          right: 31%; } }
    .home .testimonials__dot--3 {
      top: 79%;
      left: 2%; }
      @media (min-width: 768px) {
        .home .testimonials__dot--3 {
          top: 92%;
          left: 7%; } }
      @media (min-width: 1280px) {
        .home .testimonials__dot--3 {
          top: 83%;
          left: 80%;
          width: 15px;
          height: 15px; } }
    .home .testimonials__dot--4 {
      background-color: #FFFFFF;
      width: 43px;
      height: 43px;
      right: -9%;
      bottom: 32%; }
      @media (min-width: 768px) {
        .home .testimonials__dot--4 {
          width: 100px;
          height: 100px;
          right: -5%;
          bottom: 17%; } }
      @media (min-width: 1280px) {
        .home .testimonials__dot--4 {
          right: -3%;
          bottom: 39%; } }
    .home .testimonials__dot--5 {
      display: none;
      width: 10px;
      height: 10px;
      background-color: #FFFFFF; }
      @media (min-width: 768px) {
        .home .testimonials__dot--5 {
          display: block;
          left: 8%;
          bottom: 34%; } }
      @media (min-width: 1280px) {
        .home .testimonials__dot--5 {
          left: 25%;
          bottom: 18%; } }
    .home .testimonials__dot--6 {
      display: none; }
      @media (min-width: 1280px) {
        .home .testimonials__dot--6 {
          display: block;
          width: 24px;
          height: 24px;
          background-color: #FFFFFF;
          top: 14%;
          left: 11%; } }
    .home .testimonials__dot--7 {
      display: none; }
      @media (min-width: 1280px) {
        .home .testimonials__dot--7 {
          display: block;
          background-color: #FFFFFF;
          right: 7%;
          top: 13%;
          width: 10px;
          height: 10px; } }

.home .media {
  padding-top: 70px;
  padding-bottom: 60px;
  max-width: 872px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .home .media {
      padding-top: 190px;
      padding-bottom: 150px; } }
  @media (min-width: 1280px) {
    .home .media {
      display: flex;
      justify-content: space-between;
      padding-top: 132px;
      padding-bottom: 15px; }
      .home .media__link {
        margin-right: 28px; }
      .home .media__link:last-child {
        margin-right: 7px; } }
  .home .media__img {
    max-width: 100%;
    display: block;
    margin-bottom: 28px;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 768px) {
      .home .media__img {
        margin-bottom: 103px; } }
    @media (min-width: 1280px) {
      .home .media__img {
        height: 320px;
        align-self: center; } }
  .home .media__hgroup {
    text-align: center; }
    @media (min-width: 1280px) {
      .home .media__hgroup {
        padding-top: 42px;
        text-align: left;
        padding-left: 60px;
        padding-right: 45px; } }
  .home .media__head {
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -.8px;
    line-height: 1.2em;
    margin-bottom: 22px; }
    @media (min-width: 768px) {
      .home .media__head {
        font-size: 36px;
        line-height: 1em;
        margin-bottom: 32px;
        padding: 0 100px;
        letter-spacing: .1px; } }
    @media (min-width: 1280px) {
      .home .media__head {
        font-size: 22px;
        letter-spacing: 0;
        margin-bottom: 15px;
        padding: 0; } }
  .home .media__subheader1 {
    font-size: 14px;
    font-weight: 700;
    color: #008aae;
    padding: 0 50px;
    margin-bottom: -3px;
    line-height: 1.4em; }
    @media (min-width: 768px) {
      .home .media__subheader1 {
        font-size: 24px;
        padding: 0; } }
    @media (min-width: 1280px) {
      .home .media__subheader1 {
        font-size: 13px;
        margin-bottom: 3px; } }
  .home .media__subheader2 {
    color: #333333;
    line-height: 1.5em;
    letter-spacing: .4px;
    margin-bottom: 64px; }
    @media (min-width: 768px) {
      .home .media__subheader2 {
        font-size: 24px;
        line-height: 1.3em;
        letter-spacing: 0;
        margin-bottom: 74px;
        padding: 0 60px; } }
    @media (min-width: 1280px) {
      .home .media__subheader2 {
        font-size: 13px;
        margin-bottom: 20px;
        padding: 0; } }
  .home .media__links {
    display: flex;
    justify-content: space-between; }
  .home .media__link {
    display: block; }
  .home .media__link-img--google {
    width: 74px;
    height: 18px; }
    @media (min-width: 768px) {
      .home .media__link-img--google {
        width: 170px;
        height: 43px; } }
    @media (min-width: 1280px) {
      .home .media__link-img--google {
        width: 75px;
        height: 18px; } }
  .home .media__link-img--appstore {
    width: 73px;
    height: 19px; }
    @media (min-width: 768px) {
      .home .media__link-img--appstore {
        width: 166px;
        height: 43px; } }
    @media (min-width: 1280px) {
      .home .media__link-img--appstore {
        width: 73px;
        height: 19px; } }
  .home .media__link-img--oculus {
    width: 85px;
    height: 18px; }
    @media (min-width: 768px) {
      .home .media__link-img--oculus {
        width: 195px;
        height: 42px; } }
    @media (min-width: 1280px) {
      .home .media__link-img--oculus {
        width: 86px;
        height: 18px; } }

.home section.video {
  background: #343434;
  padding: 50px 0;
  color: #fff; }
  .home section.video .columns {
    padding: 0 50px; }
  .home section.video .video-container2 {
    position: relative;
    width: 100%;
    height: 445px; }
  .home section.video .video-container2 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20; }
  .home section.video .description {
    padding-top: 5%; }
    .home section.video .description h2 {
      font-weight: 400;
      font-size: 30px;
      -webkit-font-smoothing: antialiased; }
    .home section.video .description p {
      color: #c8c8c8;
      font-size: 18px; }

@media only screen and (max-width: 48em) {
  .home section.hero h2 {
    margin-top: 22px;
    margin-bottom: 8px;
    font-size: 35px;
    line-height: 45px; }
  .home section.hero .ribbon {
    display: none; }
  .home section.video .description {
    padding: 40px 0 0; } }

.home .row.walk {
  background-color: #eeeeee; }
  .home .row.walk .vc {
    padding-top: 40px; }
  .home .row.walk .btn {
    margin: 60px auto 30px;
    border: 2px solid #ffff41;
    border-radius: 20px;
    line-height: 20px;
    padding: 10px 10px;
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    color: #222222;
    display: block;
    text-align: center;
    width: 190px;
    text-transform: uppercase;
    font-size: 13px;
    background: #ffff41;
    font-family: 'Roboto';
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 1px;
    transition: all, 150ms, ease-in-out; }
    .home .row.walk .btn:hover {
      color: #000; }

.home .row.vendor-strip {
  background-color: #2f2f2d; }
  .home .row.vendor-strip h2 {
    font-size: 1.6875rem;
    color: #fff;
    text-align: center;
    max-width: 425px;
    margin: 0 auto  40px; }
  .home .row.vendor-strip .vc {
    padding: 70px 0; }
  .home .row.vendor-strip .vendors-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto; }

.home .slogan {
  position: relative;
  text-align: center;
  background-color: #006783;
  padding-top: 12px;
  padding-bottom: 26px; }
  .home .slogan .animation {
    height: 80px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .home .slogan .animation {
        width: 500px; } }
  .home .slogan__tagline {
    color: #ffffff;
    line-height: 1em;
    max-width: 120px;
    margin: 0 auto;
    font-size: 10px; }
  .home .slogan__button {
    margin-bottom: 14px;
    padding: 2px 30px; }

@media only screen and (min-width: 1024px) {
  .home .row.vendor-strip h2, .home .row.vendor-strip .vendors-container {
    max-width: 500px; } }

@media only screen and (min-width: 1px) and (max-width: 64em) {
  .home .row.vendor-strip .vendors-container {
    max-width: 475px; }
    .home .row.vendor-strip .vendors-container > div:first-child {
      margin-right: 30px; }
    .home .row.vendor-strip .vendors-container > div:last-child {
      margin-left: 30px; } }

@media only screen and (max-width: 475px) {
  .home .row.vendor-strip .vendors-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .home .row.vendor-strip .vendors-container > div {
      margin: 20px auto; }
    .home .row.vendor-strip .vendors-container div:first-child, .home .row.vendor-strip .vendors-container div:last-child {
      margin: 20px auto; }
    .home .row.vendor-strip .vendors-container > div:last-child {
      margin-bottom: 0; } }
